import {StyleSheet, View} from 'react-native';
import React, {useContext} from 'react';
import FNImage from '../../components/global/FnsImage/';
import {imageFromAPI} from '../../utils';
// import Layout from '../../assets/Layout';
import {allProducts} from '../../products';
import AppContext from '../../context/AppContext';
import FnsText from '../../components/text/FnsText';
import Colours from '../../assets/Colours';
import DateAndTime from 'date-and-time';
import Ordinal from 'date-and-time/plugin/ordinal';
import ProductButtons from './ProductButtons';
DateAndTime.plugin(Ordinal);

type ContentBlock = {
  code: string;
  number: number;
  event_number?: number;
  scheduled_time: string;
  status: string;
  type: string;
};

type Props = {
  item: ContentBlock;
  maxWidth?: number;
};

const ContentBlock = ({item, maxWidth}: Props) => {
  const context = useContext(AppContext);
  const {navItems, platform} = context;
  const itemProduct = item.product;
  const lastEvent = item.last_event;
  const [containerHeight, setContainerHeight] = React.useState(0);
  const [containerWidth, setContainerWidth] = React.useState(0);
  const isDesktop = platform === 'desktop';

  const product = allProducts.find(p => p.code === itemProduct.product_code);

  const defaultText = (text: string) => {
    return (
      <FnsText
        size={'Small'}
        decoration="Bold"
        style={{color: 'white', textTransform: 'uppercase'}}>
        {text}
      </FnsText>
    );
  };

  const dateText = (text: string) => {
    return (
      <FnsText
        size={'ExtraSmall'}
        decoration="SemiBold"
        style={{color: 'white', textTransform: 'uppercase'}}>
        {text}
      </FnsText>
    );
  };

  const productTextMap = {
    49: {
      top: defaultText(
        `${
          lastEvent.number === 1 ? 'Lunchtime' : 'Teatime'
        } Draw ${DateAndTime.format(
          new Date(lastEvent?.scheduled_time),
          'HH:mm',
        )}`,
      ),
      bottom: dateText(
        DateAndTime.format(
          new Date(lastEvent?.scheduled_time),
          'dddd DDD MMMM',
        ),
      ),
    },
    36: {
      top: defaultText('Draw Every 3 Mins'),
      bottom: () => null,
      // <FnsText
      //   size={'ExtraSmall'}
      //   style={{color: 'gold'}}
      //   decoration="SemiBold">
      //   LIVE!
      // </FnsText>
    },
    IL: {
      top: defaultText(
        `Main Draw ${DateAndTime.format(
          new Date(lastEvent?.scheduled_time),
          'HH:mm',
        )}`,
      ),
      bottom: dateText(
        DateAndTime.format(
          new Date(lastEvent?.scheduled_time),
          'dddd DDD MMMM',
        ),
      ),
    },
    39: {
      top: defaultText(
        `Draw ${lastEvent?.number} ${DateAndTime.format(
          new Date(lastEvent?.scheduled_time),
          'HH:mm',
        )}`,
      ),
      bottom: dateText(
        DateAndTime.format(
          new Date(lastEvent?.scheduled_time),
          'dddd DDD MMMM',
        ),
      ),
    },
    15: {
      top: defaultText(
        `Draw ${lastEvent?.number} ${DateAndTime.format(
          new Date(lastEvent?.scheduled_time),
          'HH:mm',
        )}`,
      ),
      bottom: dateText(
        DateAndTime.format(
          new Date(lastEvent?.scheduled_time),
          'dddd DDD MMMM',
        ),
      ),
    },
    HR: {
      top: defaultText(`${lastEvent.venue_name} R${lastEvent.number}`),
      bottom: dateText(
        DateAndTime.format(
          new Date(lastEvent?.scheduled_time),
          'dddd DDD MMMM',
        ),
      ),
    },
    DG: {
      top: defaultText(`${lastEvent.venue_name} R${lastEvent.number}`),
      bottom: dateText(
        DateAndTime.format(
          new Date(lastEvent?.scheduled_time),
          'dddd DDD MMMM',
        ),
      ),
    },
  };

  return (
    <View
      style={[
        styles.container,
        {backgroundColor: Colours[product?.code], maxWidth: maxWidth},
      ]}>
      <View
        onLayout={e => {
          setContainerHeight(e.nativeEvent.layout.height);
          setContainerWidth(e.nativeEvent.layout.width);
        }}
        style={styles.upperContainer}>
        <FNImage
          resizeMode={'cover'}
          imageURL={imageFromAPI(
            navItems[`secondary/${product?.path}`]?.logo_image_url,
            {
              height: isDesktop ? 200 : 125,
              width: isDesktop ? 200 : 125,
            },
          )}
          style={{
            height: containerHeight,
            width: containerWidth,
          }}
        />
      </View>
      <View style={styles.lowerContainer}>
        {productTextMap[product?.code]?.top}
        {productTextMap[product?.code]?.bottom}
      </View>
      <ProductButtons product={product} />
    </View>
  );
};

export default ContentBlock;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 5,
    overflow: 'hidden',
    // maxWidth: 250,
    // maxHeight: 200,
    width: '100%',
  },
  upperContainer: {
    flex: 1,
  },
  lowerContainer: {
    // justifyContent: 'center',
    alignItems: 'flex-end',
    paddingRight: 5,
    maxHeight: '17%',
  },
  buttonContainer: {
    flexDirection: 'row',

    justifyContent: 'space-evenly',
    maxHeight: 50,
  },
});
