/**
 * HomePage
 * When the component mounts, make two API calls, using Promise.all:
 *  * getNextToGo() -> Get time of next event for each product
 *  * getLatestResults() -> Get latest result for products selected in the CMS
 */

import React, {useCallback, useContext, useEffect} from 'react';
import PagesWrapper from '../PagesWrapper';
import WebPageWrapper from '../WebPageWrapper';
import Text from '../../components/text/FnsText';

import Client from '../../client/SisClient';

import AppContext from '../../context/AppContext';

import GlobalStyles from '../../assets/styles';

import {StaticPageProps} from '../../@types/pages'; // eslint-disable-line @typescript-eslint/no-unused-vars
import ContentBlockContainer from './ContentBlockContainer';
import {useFocusEffect} from '@react-navigation/native';
import ContentBlock from './ContentBlock';
import PartnerOfferContainer from './PartnerOfferContainer';
import PartnerOffer from './PartnerOffer';
import HomePageCarousel from './HomePageCarousel';
import FnsModal from './FnsModal';
// import PartnerOfferModalContent from './PartnerOfferModalContent';
import {AppState, View} from 'react-native';
import ActivityIndicator from '../../components/global/ActivityIndicator';
import Colours from '../../assets/Colours';

const Home: React.FC<StaticPageProps> = ({path, colors}) => {
  // Hooks
  const [currentAppState, setCurrentAppState] = React.useState('');
  const [focused, setFocused] = React.useState(true);
  const [homepageGrid, setHomepageGrid] = React.useState<any>([]);
  const [partnerOffers, setPartnerOffers] = React.useState<any>([]);
  const [carousel, setCarousel] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(true);

  const context = useContext(AppContext);
  const {platform, setShowPartnerPopup, showPartnerPopup} = context;
  const desktop = platform === 'desktop';
  const Component = desktop ? WebPageWrapper : PagesWrapper;

  // Helper Functions
  const loadData = useCallback(async () => {
    if (!focused) return;
    setLoading(true);
    const proms = [
      Client.getHomePageGrid(),
      Client.getHomePageCarousel(),
      Client.getPartnerOffers(),
    ];

    Promise.all(proms)
      .then(res => {
        // place partner offers in grid
        const offersForGrid = res[2]?.content?.partner_offers.filter(
          po => po.home_page_grid_order,
        );

        const sortedGrid = res[0].content.data
          .concat(offersForGrid || [])
          .sort(
            (a, b) =>
              (a.home_page_grid_order || a.product.home_page_grid_order) -
              (b.home_page_grid_order || b.product.home_page_grid_order),
          );

        setHomepageGrid(sortedGrid);
        setPartnerOffers(res[2].content.partner_offers);
        setCarousel(res[1].content.carousel);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [focused]);

  const handleChange = useCallback(
    (newState: string) => {
      // If we come back to active from BG, we will test to see if any UpNexts hae expired
      // If so, we make the call to get some new ones
      if (newState === 'active' && currentAppState === 'background') {
        return loadData();
      }

      setCurrentAppState(newState);
    },
    [currentAppState, loadData],
  );

  useEffect(() => {
    return () => setFocused(false);
  }, []);

  useFocusEffect(
    useCallback(() => {
      loadData();

      const timer = setInterval(() => {
        Client.getHomePageCarousel().then(res => {
          setCarousel(res.content.carousel);
        });
      }, 1000 * 90);

      return () => clearInterval(timer);
    }, [loadData]),
  );

  useFocusEffect(
    useCallback(() => {
      AppState.addEventListener('change', handleChange);

      return () => AppState.removeEventListener('change', handleChange);
    }, [handleChange]),
  );

  interface productMap {
    [name: string]: number;
  }

  return (
    <Component path={path} bgColor={desktop ? colors.background_color : 'blue'}>
      {loading ? (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <ActivityIndicator size="extraLarge" color={Colours['49s']} />
        </View>
      ) : (
        <>
          {carousel?.length ? <HomePageCarousel carousel={carousel} /> : null}
          <ContentBlockContainer>
            {homepageGrid?.map((item, index) => {
              return item?.product?.product_code ? (
                <ContentBlock key={index} item={item} />
              ) : (
                <PartnerOffer key={index} item={item} parent="content-block" />
              );
            })}
          </ContentBlockContainer>

          <Text
            decoration={'Bold'}
            size={desktop ? 'MediumLarge' : undefined}
            style={[
              GlobalStyles.uppercase,
              GlobalStyles.alignSelfStart,
              desktop ? GlobalStyles.oceanBlueText : GlobalStyles.whiteText,
              GlobalStyles.paddingTop,
            ]}>
            {'Latest Partner Offers:'}
          </Text>

          <PartnerOfferContainer>
            {partnerOffers?.map(item => (
              <PartnerOffer key={item.id} item={item} parent="standard" />
            ))}
          </PartnerOfferContainer>
        </>
      )}

      <FnsModal
        title={'Latest Partner Offers'}
        visible={showPartnerPopup}
        setVisible={setShowPartnerPopup}
        apiCall={Client.getPartnerOffersModal}>
        {/* <PartnerOfferModalContent /> */}
      </FnsModal>
    </Component>
  );
};

export default Home;
