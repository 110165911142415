/**
 * PagesWrapper, as the name suggests, acts as a wrapper around our screens.
 * It applies the LinearGradient Background, based on the page's menu's background_colour, as set in the CMS.
 * If there is no colour, it will default to the BrandBlue.
 *
 * Arguments:
 * - children: any children to be rendered
 * - path: the path of the page that will be used to fetch adertising / content
 * - style: any style to apply to the outer View
 * - bgColor: hex code that will differ based on the menu of the page
 * - fetchContent: when true, we will fetch the content of the page - will need to pass true when <PageContent /> is on the parent component
 */

import React, {useState, useEffect, useContext, useRef} from 'react';
import {
  View,
  useWindowDimensions,
  Pressable,
  Image,
  Platform,
} from 'react-native';

import AdvertisementSwiper from '../components/advertising/AdvertismentSwiper';
import DesktopBanner from '../components/advertising/DesktopBanner';
import Bookmaker from '../components/bookmakers/Bookmaker';
import Card from '../components/global/Card';
import Row from '../components/global/Row';
import Text from '../components/text/FnsText';
import Footer from '../components/navigation/Footer';
import AspectRatio from '../components/global/AspectRatio';
import LinearGradient from '../components//global/LinearGradient';

import {loadPageContent} from '../utils';
import AppContext from '../context/AppContext';
import Layout from '../assets/Layout';
import GlobalStyles from '../assets/styles';

import {
  PagesWrapperProps, // eslint-disable-line @typescript-eslint/no-unused-vars
  AdvertisingObject, // eslint-disable-line @typescript-eslint/no-unused-vars
  leaderboardObj, // eslint-disable-line @typescript-eslint/no-unused-vars
} from '../@types/pages';

import BannerAd from '../components/global/BannerAd/BannerAd';
import {useFocusEffect, useRoute} from '@react-navigation/native';
import {CarouselProvider, Slider, Slide} from 'pure-react-carousel';

const ChevronLeftBlue = '/ChevronLeftBlue.svg';

const WebPageWrapper: React.FC<PagesWrapperProps> = ({
  children,
  path,
  style,
  productMenu,
  bgColor,
}) => {
  const {width} = useWindowDimensions();
  const context = useContext(AppContext);
  const background =
    bgColor || context.navItems.products.colors.background_color;
  const backgroundColor = {backgroundColor: background};
  const {
    bookmakers,
    currentStack,
    setCurrentStack,
    initialLoadComplete,
    setInitialLoadComplete,
    wrapperBackButtonFunction,
    setWrapperBackButtonFunction,
  } = context;

  const [loading, setLoading] = useState(true);
  const [displayBookmakers, setDisplayBookmakers] = useState(false);
  const [content, setContent] = useState('');
  const [advertising, setAdvertising] = useState({} as AdvertisingObject);
  const [headerImageURL, setHeaderImageURL] = useState<string>();
  const route = useRoute();

  useEffect(() => {
    let cancelRequest = false;

    (async () => {
      const page = await loadPageContent(path);
      if (cancelRequest) {
        return;
      }

      if (page) {
        setContent(page.content);
        setDisplayBookmakers(page.displayBookmakers);
        page.advertising.top_panel =
          page.advertising.top_panel &&
          page.advertising.top_panel.filter(
            (tp: leaderboardObj) => tp.large_image_url,
          );
        setAdvertising(page.advertising);
        setHeaderImageURL(page.headerImage);
      }

      setLoading(false);
    })();

    return () => {
      cancelRequest = true;
    };
  }, [path]);

  // Used to handle the sitatuion when a user loads the page on a route i.e. https://.../49s The sub menu for 49s would not show
  useEffect(() => {
    if (!currentStack && !initialLoadComplete) {
      setCurrentStack(productMenu);
      setInitialLoadComplete(true);
    }
  }, [
    setCurrentStack,
    setInitialLoadComplete,
    initialLoadComplete,
    productMenu,
    currentStack,
  ]);
  const viewRef: any = useRef(null);

  useFocusEffect(
    React.useCallback(() => {
      const contentContainerDiv = document.getElementById('content-container');
      if (!contentContainerDiv) {
        viewRef?.current?.setAttribute('id', 'content-container');
      }
      return () => {
        viewRef?.current?.removeAttribute('id');
      };
    }, []),
  );

  if (loading) {
    return null;
  }

  ///////////// Styles
  // TODO: Defo a better way to do this. Currently getting the 160 from GLobalStyles.hardwidth, Layout.spacerXL from Globalstyles.marginleftL and Layout.spacerSM from globalstyles.marginhorizontalSM
  const widthOver1920 = width > 1920 ? (width - 1920) / 2 : 0;
  const footerPanel = {
    paddingHorizontal: 160 + Layout.spacerXL + Layout.spacerSM + widthOver1920,
  };
  const stickyPos = {
    position: 'sticky',
  };

  const isHomePage = route?.name === 'Home';
  const isWeb = Platform.OS === 'web';

  // We want to limit the banner ad to a size of 225px
  const paddingTopPct = {
    paddingTop: isHomePage ? 'min(12.5%, 120px)' : 'min(20%, 225px)',
  };
  const pageContainer = {
    paddingHorizontal: widthOver1920,
  };

  const adPlacementIDs = ['6565f2d109ba2171d1441145'];

  return (
    <>
      <LinearGradient
        webGradientType={'webBackground'}
        colours={[background, GlobalStyles.black]}
        style={[GlobalStyles.flexGrow, backgroundColor, pageContainer]}>
        <View
          style={[
            GlobalStyles.alignCenter,
            GlobalStyles.flexGrow,
            GlobalStyles.paddingHorizontalMS,
            GlobalStyles.paddingBottomMS,
            //  GlobalStyles.paddingTopL,
            GlobalStyles.fullWidth,
            style,
          ]}>
          <View
            style={[
              GlobalStyles.row,
              width > 1270 ? GlobalStyles.width90pct : GlobalStyles.width95pct,
              GlobalStyles.justifyCenter,
            ]}>
            <View
              style={[
                GlobalStyles.marginRight,
                GlobalStyles.hardWidth,
                GlobalStyles.borderRadiusSM,
                GlobalStyles.hiddenOverflow,
                GlobalStyles.marginBottomSM,
                GlobalStyles.hardHeight,
                stickyPos,
                GlobalStyles.top20,
              ]}>
              {/* left body banner */}
              {/* <BannerAd richMedia={false} route={route.name} placementId={'62601670df8d520b1c653a2f'}  style={{minWidth: "160px", minHeight: "600px"}} /> */}
            </View>

            <View
              nativeID="content-container"
              ref={viewRef}
              style={[
                GlobalStyles.flex,
                GlobalStyles.bodyBG,
                GlobalStyles.padding,
                GlobalStyles.borderRadiusSM,
                {maxWidth: 1100, minWidth: 850},
              ]}>
              {wrapperBackButtonFunction && (
                <View
                  style={[
                    GlobalStyles.fullWidth,
                    GlobalStyles.paddingBottomSM,
                    GlobalStyles.row,
                  ]}>
                  <Pressable
                    style={[GlobalStyles.row, GlobalStyles.marginRightSM]}
                    onPress={() => {
                      wrapperBackButtonFunction();
                      setWrapperBackButtonFunction(undefined);
                    }}>
                    <Image
                      source={ChevronLeftBlue as any}
                      style={[
                        GlobalStyles.iconWidthExtraSmall,
                        GlobalStyles.iconHeightExtraSmall,
                      ]}
                    />
                    <Text
                      decoration={'Bold'}
                      style={GlobalStyles.oceanBlueText}>
                      {'Back'}
                    </Text>
                  </Pressable>
                  <View style={GlobalStyles.flex} />
                </View>
              )}
              {isWeb && isHomePage ? (
                <View
                  style={{
                    marginBottom: 15,
                  }}>
                  <CarouselProvider
                    naturalSlideWidth={width}
                    naturalSlideHeight={180}
                    totalSlides={adPlacementIDs.length}
                    interval={5000}
                    isPlaying
                    infinite>
                    <Slider>
                      {adPlacementIDs.map((id, index) => (
                        <Slide key={id} index={index}>
                          <BannerAd
                            richMedia={false}
                            placementId={id}
                            style={{
                              minWidth: '728px',
                              minHeight: '90px',
                            }}
                          />
                        </Slide>
                      ))}
                    </Slider>
                  </CarouselProvider>
                </View>
              ) : null}

              {(!isHomePage || !isWeb) && advertising?.top_panel?.length ? (
                <View
                  style={[
                    GlobalStyles.fullWidth,
                    !isHomePage
                      ? GlobalStyles.marginBottomL
                      : GlobalStyles.marginBottomXS,
                  ]}>
                  <AspectRatio style={paddingTopPct}>
                    {advertising.top_panel.length > 1 ? (
                      <View
                        style={[
                          GlobalStyles.fullWidth,
                          GlobalStyles.fullHeight,
                        ]}>
                        <AdvertisementSwiper
                          duration={advertising.duration}
                          advertisements={advertising.top_panel}
                          placementType={'top_panel'}
                          style={
                            isHomePage
                              ? {
                                  maxHeight: 90,
                                  MaxWidth: 728,
                                }
                              : {}
                          }
                          controlsEnabled={!isHomePage}
                        />
                      </View>
                    ) : (
                      <DesktopBanner
                        advertisement={advertising.top_panel[0]}
                        placementType={'top_panel'}
                        style={GlobalStyles.fullWidth}
                      />
                    )}
                  </AspectRatio>
                </View>
              ) : null}

              {React.Children.map(children, child => {
                if (child) {
                  return React.cloneElement(child, {
                    content: content,
                    headerImageURL: headerImageURL,
                  });
                }
              })}

              {displayBookmakers && bookmakers && (
                <View
                  style={[
                    GlobalStyles.fullWidth,
                    GlobalStyles.borderRadiusSM,
                    GlobalStyles.hiddenOverflow,
                    GlobalStyles.paddingVerticalSM,
                    GlobalStyles.zindexNegativeOne,
                  ]}>
                  <Card
                    noHeader={true}
                    BodyContent={
                      <>
                        <Text
                          size={'MediumLarge'}
                          decoration={'Bold'}
                          style={[
                            GlobalStyles.uppercase,
                            GlobalStyles.oceanBlueText,
                          ]}>
                          {'Latest Partner Offers:'}
                        </Text>
                        <Row style={GlobalStyles.flexWrapWrap}>
                          {bookmakers.map((bookmaker, index) => {
                            const bookmakerProps = {
                              ...bookmaker,
                              imageWrapperStyle:
                                GlobalStyles.advertisingBannerWidthM,
                            };

                            return (
                              <View
                                key={`bookmaker${index}`}
                                style={
                                  width < 1500
                                    ? GlobalStyles.flexBasisHalf
                                    : GlobalStyles.flexBasis45pct
                                }>
                                <Bookmaker bookmaker={bookmakerProps} />
                              </View>
                            );
                          })}
                        </Row>
                      </>
                    }
                  />
                </View>
              )}

              <View style={[GlobalStyles.fullWidth, GlobalStyles.paddingTop]}>
                <AspectRatio
                  style={isHomePage ? {paddingTop: 250} : paddingTopPct}>
                  {/* Bottom banner ad */}
                  {route?.name !== 'Responsible Gambling' && (
                    <>
                      <BannerAd
                        richMedia={false}
                        placementId="626016821f2683613b5e0afa"
                        style={{minWidth: '728px', minHeight: '90px'}}
                      />
                      <BannerAd
                        richMedia={true}
                        placementId="626016a4df8d520b1c653a33"
                        style={{display: 'none'}}
                      />
                      {/* <BannerAd
                        richMedia={true}
                        placementId="63172187b03c5d46ba6c564f"
                        style={{display: 'none'}}
                      /> */}
                    </>
                  )}
                </AspectRatio>
              </View>
            </View>
            <View
              style={[
                GlobalStyles.marginLeft,
                GlobalStyles.hardWidth,
                GlobalStyles.borderRadiusSM,
                GlobalStyles.hiddenOverflow,
                GlobalStyles.marginBottomSM,
                GlobalStyles.hardHeight,
                GlobalStyles.top20,
                stickyPos,
              ]}>
              {/* right banner ad */}
              {/* <BannerAd richMedia={false} route={route.name} placementId={'62601670df8d520b1c653a2f'} style={{minWidth: "160px", minHeight: "600px"}} /> */}
            </View>
          </View>
        </View>
      </LinearGradient>

      <Footer style={footerPanel} />
    </>
  );
};

export default WebPageWrapper;
